@import '../../variables.module.scss';

.teacher{
    margin-top: 200px;

    &__wrapper{
        flex-direction: column;
        align-items: center;
        position: relative;

        &_title{
            font-size: 32px;
            letter-spacing: .3%;
            color: $yellow;
            margin-top: 48px;
        }

        &__block{
            margin-top: 24px;
            justify-content: space-between;
            width: 100%;

            &__text{
                flex-direction: column;
                font-size: 20px;
                color: $white;
                margin-right: 54px;
                justify-content: center;

                &__wrapper{
                    align-items: center;
                    margin: 12px 0px 12px 0px;

                    &_img{
                        width: 28px;
                        height: 28px;
                    }
                }

                p{
                    margin-left: 12px;
                }

                span{
                    color: $yellow;
                }
            }

            &_img{
                width: 456px;
                height: 498px;

                &_second{
                    position: absolute;
                    width: 367px;
                    height: 400px;
                    top: 50%;
                    left: -310px;
                    transform: translateY(-50%);
                }
            }
        }
        &_bg{
            position: absolute;
            z-index: -1;
            top: 0%;
            left: 5%;
        }
    }
}

@media only screen and (max-width: 1400px) {
    .teacher{
        margin-top: 160px;
    
        &__wrapper{
    
            &__block{
                margin-top: 24px;
    
                &__text{
                    flex-direction: column;
                    font-size: 20px;
                    color: $white;
                    margin-right: 54px;
                    justify-content: center;
    
                    &__wrapper{
                        align-items: center;
                        margin: 12px 0px 12px 0px;
                    }
    
                    p{
                        margin-left: 12px;
                    }
    
                    span{
                        color: $yellow;
                    }
                }
    
                &_img{
                    width: 100%;
                    height: 468px;
                }
            }
            &_bg{
                position: absolute;
                z-index: -1;
                top: 0%;
                left: 5%;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .teacher{
        margin-top: 80px;
    
        &__wrapper{
    
            &__block{
                margin-top: 0px;
    
                &__text{
                    flex-direction: column;
                    font-size: 20px;
                    color: $white;
                    margin-right: 54px;
                    justify-content: center;
    
                    &__wrapper{
                        align-items: center;
                        margin: 12px 0px 12px 0px;
                    }
    
                    p{
                        margin-left: 12px;
                    }
    
                    span{
                        color: $yellow;
                    }
                }
    
                &_img{
                    width: 100%;
                    height: inherit;
                }
            }
            &_bg{
                position: absolute;
                z-index: -1;
                top: 20%;
                left: 5%;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .teacher{

        margin-top: 64px;
    
        &__wrapper{

            &_title{
                font-size: 24px;
                margin-top: 32px;
            }
    
            &__block{
                flex-direction: column-reverse;
                margin-top: 0px;
                width: 100%;
    
                &__text{
                    flex-direction: column;
                    font-size: 18px;
                    color: $white;
                    margin-right: 0px;
                    justify-content: center;
    
                    &__wrapper{
                        align-items: center;
                        margin: 12px 0px 12px 0px;
                    }
    
                    p{
                        margin-left: 12px;
                    }
    
                    span{
                        color: $yellow;
                    }
                }
    
                &_img{
                    width: 100%;
                    max-width: 500px;
                    height: 400px;
                    margin: 0 auto;
                    margin-bottom: 24px;
                }
            }
            &_bg{
                position: absolute;
                z-index: -1;
                top: 60%;
                left: 15%;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .teacher{

        margin-top: 64px;
    
        &__wrapper{

            &_title{
                font-size: 24px;
                margin-top: 32px;
                margin-bottom: 0px;
            }
    
            &__block{

                &__text{
                    font-size: 15px;
    
                    &__wrapper{
                        align-items: center;
                        margin: 12px 0px 12px 0px;

                        &_img{
                            width: 20px;
                            height: 20px;
                        }
                    }
    
                    p{
                        margin-left: 8px;
                    }
    
                }
    
                &_img{
                    height: 320px;
                    margin-top: 16px;
                }
            }
            &_bg{
                position: absolute;
                z-index: -1;
                top: 50%;
                left: -15%;
            }
        }
    }
}