@import '../../variables.module.scss';

.lesson{
    background: url('../../img/backgroundLesson.svg') center center/cover no-repeat;
    width: 100%;
    height: 651px;
    margin-top: 80px;
    align-items: center;

    &__wrapper{
        justify-content: space-between;
        align-items: center;

        &_img{
            width: 384px;
            height: 384px;
        }

        &__form{
            flex-direction: column;
            width: 559px;
            p{
                color: $white;
            }
            &_title{
                font-size: 32px;
                line-height: 46px;
                letter-spacing: 0.1%;
                text-align: center;
            }

            &_descr{
                margin-top: 24px;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: 0.2%;
                margin: 0 auto;
                padding-top: 24px;
            }

            &_name{
                margin-top: 32px;
                padding: 12px 0px 12px 24px;
                border: 1px solid $yellow;
                border-radius: 15px;
                font-size: 24px;
                line-height: 31px;
                color: $white;
                background-color: rgba(255, 255, 255, 0);
                outline:none;
                width: 100%;
            }
            &_btn{
                margin: 0 auto;
                background: #C99F62;
                border-radius: 0px 20px;
                height: 60px;
                width: 100%;
                // padding: 24px;
                font-size: 24px;
                line-height: 31px;
                color: $main;
                border: none;
                margin-top: 12px;
                transition: .5s all;
            }
            &_btn:hover{
                background-color: $white;
            }
            &_btn:disabled{
                background-color: #696764;
                cursor: default;
            }
            &_devider{
                height: 1px;
                background-color: $yellow;
                margin-top: 32px;
            }
            &__styleError{
                flex-direction: column;
                margin-top: 8px;
                opacity: 0;
                font-size: 18px;
                p{
                    color: red
                }
            }
            &_showError{
                opacity: 100;
            }
            &_icons{
                margin: 0 auto;
                padding-top: 32px;

                &__link{

                    width: 56px;
                    height: 56px;
                    border: 1px solid $yellow;
                    margin: 0px 14px;
                    border-radius: 50px;
                    align-items: center;
                    justify-content: center;
                    display: flex;

                    &_element{
                        width: 52px;
                        height: 52px;
                    }
                }
            }
            &__sendForm{
                height: 255px;
                margin-top: 24px;
                border-radius: 50px;
                justify-content: center;
                align-items: center;

                &_active{
                    background-color: rgba(195, 159, 107, 0.8);
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: $white;
                    height: 255px;
                    border-radius: 50px;
                }

                &_greeting{
                    font-size: 32px;
                }

                &_info{
                    margin-top: 16px;
                    font-size: 18px;
                    padding: 0px 24px;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    span{
                        color: $white;
                        font-size: 20px;
                        margin-top: 4px;
                    }
                }
            }
        }
    }

    &__loading{
        margin: 0 auto;
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid #C99F62;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}


input::placeholder{
    color: #867E7A
}

@media only screen and (max-width: 1400px) {
    .lesson{

        &__wrapper{
    
            &_img{
                width: 40%;
                height: 384px;
            }
    
            &__form{

                width: 50%;

                &_title{
                    font-size: 28px;
                    line-height: 32px;
                }
    
                &_descr{
                    margin-top: 16px;
                    font-size: 24px;
                    line-height: 24px;
                    padding-top: 24px;
                }
    
                &_name{
                    margin-top: 32px;
                    padding: 12px 0px 12px 24px;
                    font-size: 20px;
                    line-height: 24px;
                    width: 100%;
                }
                &_btn{
                    margin: 0 auto;
                    background: #C99F62;
                    border-radius: 0px 20px;
                    height: 60px;
                    width: 100%;
                    font-size: 20px;
                    line-height: 31px;
                    margin-top: 12px;
                }
                &_devider{
                    height: 1px;
                    background-color: $yellow;
                    margin-top: 32px;
                }
                &__styleError{
                    flex-direction: column;
                    margin-top: 8px;
                    opacity: 0;
                    font-size: 18px;
                    p{
                        color: red
                    }
                }
                &_showError{
                    opacity: 100;
                }
                &_icons{
                    margin: 0 auto;
                    padding-top: 32px;
    
                    &__link{

                        width: 48px;
                        height: 48px;
                        border: 1px solid $yellow;
                        margin: 0px 14px;
                        border-radius: 50px;
                        align-items: center;
                        justify-content: center;
                        display: flex;
    
                        &_element{
                            width: 42px;
                            height: 42px;
                        }
                    }
                }
                &__sendForm{
                    height: 255px;
                    background-color: rgba(195, 159, 107, 0.8);
                    margin-top: 24px;
                    border-radius: 50px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: $white;
    
                    &_greeting{
                        font-size: 28px;
                    }
    
                    &_info{
                        margin-top: 16px;
                        font-size: 16px;
                        padding: 0px 24px;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        text-align: center;
    
                        span{
                            color: $white;
                            font-size: 20px;
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .lesson{

        &__wrapper{
    
            &_img{
                display: none;
            }
    
            &__form{

                width: 100%;

                &_title{
                    font-size: 28px;
                    line-height: 32px;
                }
    
                &_descr{
                    margin-top: 16px;
                    font-size: 24px;
                    line-height: 24px;
                    padding-top: 24px;
                }
    
                &_name{
                    margin-top: 32px;
                    padding: 12px 0px 12px 24px;
                    font-size: 20px;
                    line-height: 24px;
                    width: 50%;
                }
                &_btn{
                    margin: 0 auto;
                    background: #C99F62;
                    border-radius: 0px 20px;
                    height: 60px;
                    width: 50%;
                    font-size: 20px;
                    line-height: 31px;
                    margin-top: 12px;
                }
                &_devider{
                    height: 1px;
                    background-color: $yellow;
                    margin-top: 32px;
                }
                &__styleError{
                    flex-direction: column;
                    margin-top: 16px;
                    opacity: 0;
                    font-size: 16px;
                    p{
                        color: red
                    }
                }
                &_showError{
                    opacity: 100;
                }
                &_icons{
                    margin: 0 auto;
                    padding-top: 32px;
    
                    &__link{

                        width: 48px;
                        height: 48px;
                        border: 1px solid $yellow;
                        margin: 0px 14px;
                        border-radius: 50px;
                        align-items: center;
                        justify-content: center;
                        display: flex;
    
                        &_element{
                            width: 42px;
                            height: 42px;
                        }
                    }
                }

                &__sendForm{
                    height: 255px;
                    background-color: rgba(195, 159, 107, 0.8);
                    margin-top: 24px;
                    border-radius: 50px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: $white;
    
                    &_greeting{
                        font-size: 28px;
                    }
    
                    &_info{
                        margin-top: 16px;
                        font-size: 16px;
                        padding: 0px 48px;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        text-align: center;
    
                        span{
                            color: $white;
                            font-size: 20px;
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .lesson{
        margin-top: 40px;
        height: 550px;

        &__wrapper{
    
            &_img{
                display: none;
            }
    
            &__form{

                width: 100%;

                &_title{
                    font-size: 22px;
                    line-height: 32px;
                }
    
                &_descr{
                    margin-top: 16px;
                    font-size: 20px;
                    line-height: 24px;
                    padding-top: 12px;
                }
    
                &_name{
                    margin-top: 24px;
                    padding: 12px 0px 12px 24px;
                    font-size: 20px;
                    line-height: 24px;
                    width: 80%;
                }
                &_btn{
                    margin: 0 auto;
                    background: #C99F62;
                    border-radius: 0px 20px;
                    height: 60px;
                    width: 80%;
                    font-size: 20px;
                    line-height: 31px;
                    margin-top: 12px;
                }
                &_devider{
                    height: 1px;
                    background-color: $yellow;
                    margin-top: 32px;
                }
                &__styleError{
                    flex-direction: column;
                    margin-top: 16px;
                    opacity: 0;
                    font-size: 14px;
                    p{
                        color: red
                    }
                }
                &_showError{
                    opacity: 100;
                }
                &_icons{
                    margin: 0 auto;
                    padding-top: 32px;
    
                    &__link{

                        width: 48px;
                        height: 48px;
                        border: 1px solid $yellow;
                        margin: 0px 14px;
                        border-radius: 50px;
                        align-items: center;
                        justify-content: center;
                        display: flex;
    
                        &_element{
                            width: 42px;
                            height: 42px;
                        }
                    }
                }

                &__sendForm{
                    height: 255px;
                    background-color: rgba(195, 159, 107, 0.8);
                    margin-top: 24px;
                    border-radius: 50px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: $white;
    
                    &_greeting{
                        font-size: 28px;
                    }
    
                    &_info{
                        margin-top: 16px;
                        font-size: 16px;
                        padding: 0px 16px;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        text-align: center;
    
                        span{
                            color: $white;
                            font-size: 20px;
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .lesson{
        height: 550px;

        &__wrapper{
    
            &_img{
                display: none;
            }
    
            &__form{

                width: 100%;

                &_title{
                    font-size: 17px;
                }
    
                &_descr{
                    margin-top: 16px;
                    font-size: 16px;
                    line-height: 24px;
                    padding-top: 12px;
                }
    
                &_name{
                    margin-top: 24px;
                    padding: 12px 0px 12px 24px;
                    font-size: 15px;
                    line-height: 24px;
                    width: 100%;
                }
                &_btn{
                    margin: 0 auto;
                    background: #C99F62;
                    border-radius: 0px 20px;
                    height: 60px;
                    width: 100%;
                    font-size: 14px;
                    margin-top: 12px;
                }
                &_devider{
                    height: 1px;
                    background-color: $yellow;
                    margin-top: 32px;
                }
                &__styleError{
                    flex-direction: column;
                    margin-top: 16px;
                    opacity: 0;
                    font-size: 14px;
                    p{
                        color: red
                    }
                }
                &_showError{
                    opacity: 100;
                }
                &_icons{
                    margin: 0 auto;
                    padding-top: 32px;
    
                    &__link{

                        width: 48px;
                        height: 48px;
                        border: 1px solid $yellow;
                        margin: 0px 14px;
                        border-radius: 50px;
                        align-items: center;
                        justify-content: center;
                        display: flex;
    
                        &_element{
                            width: 42px;
                            height: 42px;
                        }
                    }
                }

                &__sendForm{
                    height: 255px;
                    background-color: rgba(195, 159, 107, 0.8);
                    margin-top: 24px;
                    border-radius: 50px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: $white;
    
                    &_greeting{
                        font-size: 22px;
                    }
    
                    &_info{
                        margin-top: 16px;
                        font-size: 14px;
                        padding: 0px 16px;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        text-align: center;
    
                        span{
                            color: $white;
                            font-size: 18px;
                            margin-top: 12px;
                        }
                    }
                }
            }
        }
    }
}