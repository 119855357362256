@import '../../variables.module.scss';

.map{
    color: $white;
    font-size: 24px;
    margin-top: 80px;

    &__wrapper{
        height: 406px;
        margin-top: 40px;

        &__map{
            width: 66%;
            background-color: $yellow;
        }

        &__address{
            flex-direction: column;
            margin-left: 24px;
            justify-content: space-between;

            &_title{
                color: $yellow;
            }

            &__mainInfo{
                flex-direction: column;

                &_element{
                    margin: 8px 0px;

                    a{
                        color: $white
                    }
                }
            }

            &__social{

                &__link{
                    margin: 0px 8px;
                    width: 40px;
                    height: 40px;
                    border: 1px solid $yellow;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    border-radius: 50px;

                    &_img{
                        
                    }
                }
            }
        }
    }
}

.div{
    div{
        display: block;
    }
}

@media only screen and (max-width: 1400px) {
    .map{

        font-size: 20px;

        &__wrapper{
    
            &__address{
    
                &_title{
                    color: $yellow;
                    font-size: 24px;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .map{

        font-size: 20px;

        &__wrapper{

            height: inherit;
            flex-direction: column;

            &__map{
                width: 100%;
                height: 300px;
                background-color: $yellow;
            }

            &__address{

                margin-left: 0px;

                &_title{
                    color: $yellow;
                    margin: 24px 0px;
                    text-align: center;
                }
    
                &__mainInfo{
                    flex-direction: column;
                    align-items: center;
    
                    &_element{
                        margin: 8px 0px;
    
                        a{
                            color: $white
                        }
                    }
                }
    
                &__social{

                    margin: 0 auto;
                    padding-top: 24px;
    
                    &__link{
                        margin: 0px 8px;
                        width: 40px;
                        height: 40px;
                        border: 1px solid $yellow;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        border-radius: 50px;
    
                        &_img{
                            
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .map{

        font-size: 16px;

        &__wrapper{

            height: inherit;
            flex-direction: column;

            &__map{
                width: 100%;
                height: 300px;
                background-color: $yellow;
            }

            &__address{

                margin-left: 0px;

                &_title{
                    color: $yellow;
                    margin: 24px 0px;
                    text-align: center;
                }
    
                &__mainInfo{
                    flex-direction: column;
                    align-items: center;
    
                    &_element{
                        margin: 8px 0px;
    
                        a{
                            color: $white
                        }
                    }
                }
    
                &__social{

                    margin: 0 auto;
                    padding-top: 24px;
    
                    &__link{
                        margin: 0px 8px;
                        width: 36px;
                        height: 36px;
                        border: 1px solid $yellow;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        border-radius: 50px;
    
                        &_img{
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
            }
        }
    }
}