@import '../../variables.module.scss';

.feedback{
    margin-top: 100px;

    &__mobile{
        display: none;
    }

    &__wrapper{
        flex-direction: row;
        justify-content: space-between;
        position: relative;

        &__block{
            width: 46%;
            margin-top: 16px;
            flex-direction: column;

            &_text{
                color: $white;
            }

            &_img{
                width: 150px;
                height: 150px;
                float:left; 
                margin: 7px 7px 7px 0; 
            }
        }

        &_blur{
            position: absolute;
            width: 410px;
            height: 605px;
            background: $yellow;
            filter: blur(200px);
            z-index: -1;
            top: 100px;
            left: 440px;
            -webkit-filter: blur(300px);
            -moz-filter: blur(300px);
            -ms-filter: blur(300px);
            transform: translate3d(0, 0, 0);
        }
    }
}

@media only screen and (max-width: 1400px) {

    .feedback{
        margin-top: 100px;

        &__wrapper{
            display: flex;
            &__block{
                width: 45%;
                margin-top: 16px;

                &_img{
                    width: 150px;
                    height: 150px;
                    float:left; 
                    margin: 7px 7px 7px 0; 
                }
            }

            &_blur{
                position: absolute;
                width: 410px;
                height: 605px;
                background: $yellow;
                filter: blur(200px);
                z-index: -1;
                top: 100px;
                left: 440px;
                -webkit-filter: blur(300px);
                -moz-filter: blur(300px);
                -ms-filter: blur(300px);
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .feedback{
        margin-top: 100px;

        &__mobile{
            display: flex;
            margin-top: 48px;
        }

        &__wrapper{
            display: none;
            &__block{
                width: 45%;
                margin-top: 16px;

                &_img{
                    width: 150px;
                    height: 150px;
                    float:left; 
                    margin: 7px 7px 7px 0; 
                }
            }

            &_blur{
                position: absolute;
                width: 410px;
                height: 605px;
                background: $yellow;
                filter: blur(200px);
                z-index: -1;
                top: 100px;
                left: 440px;
                -webkit-filter: blur(300px);
                -moz-filter: blur(300px);
                -ms-filter: blur(300px);
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .feedback{
        margin-top: 64px;

        &__mobile{
            display: flex;
            margin-top: 32px;
        }

    }
}