@import '../../variables.module.scss';

.price{
    margin-top: 120px;

    &__wrapper{
        flex-direction: column;
        margin-top: 80px;

        &__mobile{
            display: flex;
            flex-direction: column;

            &__tab{
                width: 100%;
                border: 1px solid $yellow;
                height: 48px;
                border-radius: 50px;
                flex-direction: row;
                margin-bottom: 40px;

                &_one{
                    width: 34%;
                    height: 48px;
                    align-items: center;
                    justify-content: center;
                    color: $yellow;
                    transition: .5s all;
                    font-size: 16px;
                    background-color: rgba(124, 124, 124, 0.2);
                    border-radius: 0px;
                    cursor: pointer;

                    &_active{
                        background-color: $yellow;
                        color: $main;
                        cursor: default;
                    }
                }

                &_one:first-child{
                    border-radius: 50px 0px 0px 50px
                }
                &_one:last-child{
                    border-radius: 0px 50px 50px 0px
                }
            }
        }

        &__sign{
            flex-direction: row;
            justify-content: space-between;

            &__adults{
                text-align: center;
                border: 1px solid #C99F62;
                border-radius: 0px 40px;
                width: 100%;
                padding: 40px 18px;
                flex-direction: column;
                width: 100%;

                p{
                    margin: 0 auto;
                    
                }

                &_title{
                    font-size: 28px;
                    line-height: 36px;
                    letter-spacing: 0.01em;
                    color: $yellow;
                }

                &_descr{
                    padding-top: 24px;
                    color: $white;
                    font-size: 20px;

                    &_hide{
                        display: flex;
                    }

                    &_hideMobilePhone{
                        display: flex;
                    }
                }

                &__pricing{
                    justify-content: space-between;
                    margin: 0px 24px;

                    &__item{
                        flex-direction: column;

                        &__mobile{
                            display: none;
                        }

                        &_priceLesson{
                            letter-spacing: .1%;
                            font-size: 24px;
                            color: $yellow;
                            padding-top: 24px;
                        }
        
                        &_amountLesson{
                            letter-spacing: .1%;
                            font-size: 24px;
                            color: $white;
                            padding-top: 24px;
                        }
                    }
                }

                &_program{
                    color: $yellow;
                    font-size: 24px;
                    line-height: 31px;
                    letter-spacing: 0.1%;
                    padding-top: 32px;
                }

                &_explaination{
                    font-size: 20px;
                    line-height: 34px;
                    color: $white;  
                    text-align: left;
                    padding-top: 24px;
                    width: 100%;
                    margin-left: 24px;

                    &_theater{
                        font-size: 20px;
                        line-height: 24px;
                        color: $white;  
                        text-align: left;
                        padding-top: 24px;
                        width: 100%;
                        margin-left: 24px;
                    }
                }

                &_firstLesson{
                    font-size: 24px;
                    color: $yellow;
                    padding-top: 54px;
                }

                &_priceLesson{
                    letter-spacing: .1%;
                    font-size: 24px;
                    color: $yellow;
                    padding-top: 24px;
                }

                &_amountLesson{
                    letter-spacing: .1%;
                    font-size: 24px;
                    color: $white;
                    padding-top: 24px;
                }

                &_afterAll{
                    padding-top: 24px;
                    color: $white;
                    font-size: 18px;
                }
            }
        }

        &__theater{
            text-align: center;
            border: 1px solid #C99F62;
            border-radius: 0px 40px;
            width: 588px;
            padding: 40px 0px;
            flex-direction: column;
            width: 100%;
            margin-top: 64px;

            &__wrapperProgram{
                justify-content: space-between;
                
                &_first{
                    width: 50%;
                }

                &_second{
                    width: 50%;
                    margin-right: 12px;
                }
            }

            &_mobile{
                display: flex;
                text-align: center;
                border: 1px solid #C99F62;
                border-radius: 0px 40px;
                width: 588px;
                padding: 20px 0px;
                padding-bottom: 40px;
                flex-direction: column;
                width: 100%;
                margin-top: 0px;
            }

            &__category{
                padding: 0px 24px;
                justify-content: center;

                &__block{
                    width: 60%;
                    flex-direction: column;
                    padding-top: 32px;
                }

                &_devider{
                    position: absolute;
                    height: 427px;
                    width: 1px;
                    background-color: $yellow;
                    right: 50%;
                    top: 74%;
                    transform: translate(0%, -45%);
                    border-radius: 10px;
                }
            }
        }

    }
}

@media only screen and (max-width: 1400px) {
    .price{
        margin-top: 80px;

        &__wrapper{
            margin-top: 64px;

            &__sign{

                &__adults{

                    &_priceLesson{
                        font-size: 20px;
                        padding-top: 24px;
                    }
    
                    &_amountLesson{
                        font-size: 20px;
                        padding-top: 12px;
                    }

                    &__pricing{
    
                        &__item{
    
                            &_priceLesson{
                                font-size: 20px;
                                padding-top: 16px;
                            }
            
                            &_amountLesson{
                                font-size: 20px;
                                padding-top: 16px;
                            }
                        }
                    }

                    &_title{
                        font-size: 24px;
                    }

                    &_explaination{
                        font-size: 18px;
                        line-height: 24px;
                        
                        &_theater{
                            font-size: 18px;
                        }
                    }

                    &_firstLesson{
                        font-size: 20px;
                        color: $yellow;
                        padding-top: 40px;

                    }

                    &_afterAll{
                        padding-top: 24px;
                        color: $white;
                        font-size: 16px;
                    }
                }
            }

            &__theater{
                text-align: center;
                border: 1px solid #C99F62;
                border-radius: 0px 40px;
                width: 588px;
                padding: 40px 0px;
                flex-direction: column;
                width: 100%;
                margin-top: 64px;

                &__wrapperProgram{
                    justify-content: space-between;
                    width: 100%;

                    &_first{
                        width: 50%;
                    }

                    &_second{
                        width: 50%;
                    }
                }

                &__category{
                    flex-direction: row;
                    justify-content: center;
                    position: relative;
                    padding: 0px 24px;


                    &__block{
                        width: 46%;
                        flex-direction: column;
                        padding-top: 32px;
                    }

                    &_devider{
                        position: absolute;
                        height: 380px;
                        width: 1px;
                        background-color: $yellow;
                        right: 50%;
                        top: 50%;
                        transform: translate(0%, -45%);
                        border-radius: 10px;
                    }
                }

                &_mobile{
                    display: flex;
                    text-align: center;
                    border: 1px solid #C99F62;
                    border-radius: 0px 40px;
                    width: 588px;
                    padding: 20px 0px;
                    flex-direction: column;
                    width: 100%;
                    margin-top: 32px;
                }
            }

        }
    }
}


@media only screen and (max-width: 992px) {

    .price{

        &__wrapper{

            margin-top: 32px;

            &__sign{

                display: none;

                &__adults{

                    width: 100%;
                    margin-top: 32px;

                    &_explaination{
                        font-size: 18px;
                        line-height: 24px;
                        color: $white;  
                        text-align: left;
                        padding-top: 24px;
                        width: 100%;
                        margin-left: 24px;

                        &_theater{
                            font-size: 15px;
                            line-height: 24px;
                            color: $white;  
                            text-align: left;
                            padding-top: 24px;
                            width: 100%;
                            margin-left: 24px;
                        }
                    }
                    
                    &_firstLesson{
                        font-size: 20px;
                        color: $yellow;
                        padding-top: 40px;

                        &_topDistance{
                            padding-top: 64px;
                        }

                    }

                    &_afterAll{
                        padding-top: 24px;
                        color: $white;
                        font-size: 18px;
                    }

                    &_priceLesson{
                        font-size: 20px;
                        padding-top: 24px;
                    }
    
                    &_amountLesson{
                        font-size: 20px;
                        padding-top: 12px;
                    }

                    &__pricing{

                        margin: 0px;
    
                        &__item{
    
                            &_priceLesson{
                                font-size: 18px;
                                padding-top: 16px;
                            }
            
                            &_amountLesson{
                                font-size: 18px;
                                padding-top: 16px;
                            }
                        }
                    }

                    &_descr{
                        font-size: 16px;

                        &_hide{
                            display: none;
                        }
                    }
                }
            }

            &__theater{
                display: none;

                &_mobile{
                    display: flex;
                    text-align: center;
                    border: 1px solid #C99F62;
                    border-radius: 0px 40px;
                    width: 588px;
                    padding: 20px 0px;
                    flex-direction: column;
                    width: 100%;
                    margin-top: 32px;
                }
            }

            &__mobile{
                display: flex;
                flex-direction: column;
            
                &__tab{
                    width: 100%;
                    border: 1px solid $yellow;
                    height: 48px;
                    flex-direction: row;

                    &_one{
                        width: 34%;
                        height: 48px;
                        border-radius: 50px;
                        align-items: center;
                        justify-content: center;
                        color: $yellow;
                        transition: .5s all;
                        font-size: 16px;
                        border-radius: 0px;

                        &_active{
                            background-color: $yellow;
                            color: $main;
                        }
                    }
                }

                &_one:first-child{
                    border-radius: 50px 0px 0px 50px
                }
                &_one:last-child{
                    border-radius: 0px 50px 50px 0px
                }

            }

        }
    }
}

@media only screen and (max-width: 767px) {
    .price{

        &__wrapper{

            &__sign{

                &__adults{

                    width: 100%;
                    margin-top: 32px;

                    &_program{
                        color: $yellow;
                        font-size: 24px;
                        line-height: 31px;
                        letter-spacing: 0.1%;
                        padding-top: 32px;
                    }

                    &_title{
                        font-size: 22px;
                        line-height: 36px;
                        padding-bottom: 12px;
                    }


                    &_descr{
                        
                        padding-top: 12px;
                        color: $white;
                        font-size: 16px;

                        &_hide{
                            display: flex;
                        }

                        &_hideMobilePhone{
                            display: none;
                        }
                    }

                    &_explaination{
                        font-size: 16px;
                        line-height: 24px;
                        color: $white;  
                        text-align: left;
                        padding-top: 0px;
                        width: 100%;
                        margin-left: 24px;

                        &_theater{
                            font-size: 15px;
                            line-height: 24px;
                            color: $white;  
                            text-align: left;
                            padding-top: 0px;
                            width: 100%;
                            margin-left: 24px;
                        }
                    }

                    &_afterAll{
                        padding-top: 16px;
                        color: $white;
                        font-size: 16px;
                    }

                    &_firstLesson{
                        font-size: 20px;
                        color: $yellow;
                        padding-top: 24px;

                        &_topDistance{
                            padding-top: 24px;
                        }
                    }

                    &__pricing{
                        flex-direction: column;
                        margin: 0px;
    
                        &__item{

                            &__mobile{
                                display: flex;
                                flex-direction: column;
                            }

                            &__mobileHide{
                                display: none;
                            }
    
                            &_priceLesson{
                                font-size: 18px;
                                padding-top: 16px;
                            }
            
                            &_amountLesson{
                                font-size: 18px;
                                padding-top: 16px;
                            }
                        }
                    }
                }
            }

            &__theater{
                display: none;

                &__wrapperProgram{
                    justify-content: space-between;
                    flex-direction: column;
                    
                    &_first{
                        width: 100%;
                    }
    
                    &_second{
                        width: 100%;
                        margin-right: 0px;
                    }
                }

                &_mobile{
                    display: flex;
                    text-align: center;
                    border: 1px solid #C99F62;
                    border-radius: 0px 40px;
                    width: 588px;
                    padding: 20px 0px;
                    flex-direction: column;
                    width: 100%;
                    margin-top: 32px;
                }

                &__category{
                    padding: 0px 12px;
                    justify-content: space-between;
                    flex-direction: column;

                    &__block{
                        width: 100%;
                        flex-direction: column;
                        padding-top: 32px;
                    }

                    &__blockLast{
                        padding-top: 80px;
                        display: none;
                    }
    
                    &_devider{
                        height: 3px;
                        width: 90%;
                        right: 0%;
                        top: 54%;
                        transform: translate(-5%, 0%);
                        border-radius: 10px;
                    }
                }
            }

            &__mobile{
                display: flex;
                flex-direction: column;
            
                &__tab{
                    width: 100%;
                    border: 1px solid $yellow;
                    height: 144px;
                    border-radius: 22px;
                    flex-direction: column;

                    &_one{
                        width: 100%;
                        height: 33%;
                        border-radius: 0px;
                        align-items: center;
                        justify-content: center;
                        color: $yellow;
                        transition: .5s all;
                        font-size: 16px;

                        &_active{
                            background-color: $yellow;
                            color: $main;
                        }
                    }

                    &_one:first-child{
                        border-radius: 20px 20px 0px 0px
                    }
                    &_one:last-child{
                        border-radius: 0px 0px 20px 20px
                    }
                }

            }

        }
    }
}

@media only screen and (max-width: 500px) {
    .price{

        &__wrapper{

            &__sign{

                &__adults{

                    width: 100%;
                    margin-top: 12px;
                    padding: 24px 8px;

                    &_program{
                        color: $yellow;
                        font-size: 18px;
                        line-height: 31px;
                        letter-spacing: 0.1%;
                        padding-top: 30px;
                    }

                    &_title{
                        font-size: 20px;
                        line-height: 24px;
                    }

                    &_title:last-child{
                        margin-top: 100px;
                    }


                    &_descr{
                        
                        padding-top: 20px;
                        color: $white;
                        font-size: 14px;
                        margin: 0px 4px;

                        &_hide{
                            display: flex;
                        }

                        &_hideMobilePhone{
                            display: none;
                        }
                    }

                    &_explaination{
                        font-size: 14px;
                        line-height: 30px;
                        color: $white;  
                        text-align: left;
                        padding-top: 0px;
                        width: 100%;
                        margin-left: 24px;

                        &_theater{
                            font-size: 14px;
                            line-height: 30px;
                            color: $white;  
                            text-align: left;
                            padding-top: 0px;
                            width: 100%;
                            margin-left: 8px;
                        }
                    }

                    &_afterAll{
                        padding-top: 24px;
                        color: $white;
                        font-size: 14px;
                    }

                    &_firstLesson{
                        font-size: 16px;
                        color: $yellow;
                        padding-top: 24px;

                        &_topDistance{
                            padding-top: 24px;
                        }
                    }

                    &_priceLesson{
                        font-size: 16px;
                        padding-top: 16px;
                    }
    
                    &_amountLesson{
                        font-size: 16px;
                        padding-top: 16px;
                    }

                    &__pricing{
                        flex-direction: column;
                        margin: 0px;
    
                        &__item{
    
                            &_priceLesson{
                                font-size: 16px;
                                padding-top: 16px;
                            }
            
                            &_amountLesson{
                                font-size: 16px;
                                padding-top: 16px;
                            }
                        }
                    }
                }
            }

            &__theater{
                display: none;

                &__wrapperProgram{
                    justify-content: space-between;
                    flex-direction: column;
                    
                    &_first{
                        width: 100%;
                    }
    
                    &_second{
                        width: 100%;
                        margin-right: 0px;
                    }
                }

                &_mobile{
                    display: flex;
                    text-align: center;
                    border: 1px solid #C99F62;
                    border-radius: 0px 40px;
                    width: 588px;
                    padding: 20px 0px;
                    flex-direction: column;
                    width: 100%;
                    margin-top: 12px;
                }

                &__category{
                    padding: 0px 12px;
                    justify-content: space-between;
                    flex-direction: column;

                    &__block{
                        width: 100%;
                        flex-direction: column;
                        padding-top: 20px;
                    }

                    &__blockLast{
                        padding-top: 64px;
                        display: none;
                    }
    
                    &_devider{
                        height: 3px;
                        width: 90%;
                        right: 0%;
                        top: 54%;
                        transform: translate(-5%, 0%);
                        border-radius: 10px;
                    }
                }
            }

            &__mobile{
                display: flex;
                flex-direction: column;
            
                &__tab{
                    width: 100%;
                    border: 1px solid $yellow;
                    height: 144px;
                    border-radius: 22px;
                    flex-direction: column;

                    &_one{
                        width: 100%;
                        height: 33%;
                        border-radius: 0px;
                        align-items: center;
                        justify-content: center;
                        color: $yellow;
                        transition: .5s all;
                        font-size: 14px;

                        &_active{
                            background-color: $yellow;
                            color: $main;
                        }
                    }

                    &_one:first-child{
                        border-radius: 20px 20px 0px 0px
                    }
                    &_one:last-child{
                        border-radius: 0px 0px 20px 20px
                    }
                }

            }

        }
    }
}