@import '../../variables.module.scss';
.main{
    width: 100%;
    height: 100vh; 
    background:  url(../../img/mainPhoto.svg) center center/cover no-repeat;

    &__wrapper{
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;

        &_title{
            margin: 0;
            font-size: 54px;
            color: #E9E2D0;
            letter-spacing: 5px;
            text-transform: uppercase;
            text-align: center;

            &_br{
                display: flex;
            }
        }

        &_descr{
            text-transform: uppercase;
            margin: 0;
            color: #E9E2D0;
            font-size: 36px;
            margin-top: 16px;
        }

        &_button{
            padding: 24px;
            background-color: $yellow;
            color: $main;
            border: none;
            border-radius: 0px 20px;
            font-size: 24px;
            margin-top: 36px;
            cursor: pointer;
            transition: .5s all;
        }

        &_button:hover{
            background-color: $white;
        }
    }
}

@media (max-height: 650px) {
    .main{
        height: 650px;
    }
  }

@media only screen and (max-width: 1400px) {
    .main{

        &__wrapper{

            &_title{
                margin: 0;
                font-size: 44px;
                color: #E9E2D0;
                letter-spacing: 5px;
                text-transform: uppercase;
                text-align: center;
            }

            &_descr{
                text-transform: uppercase;
                margin: 0;
                color: #E9E2D0;
                font-size: 28px;
                margin-top: 16px;
            }

            &_button{
                padding: 20px;
                font-size: 20px;
            }
            
        }
    }
}

@media only screen and (max-width: 992px) {
    .main{

        &__wrapper{

            &_title{
                margin: 0;
                font-size: 32px;
                color: #E9E2D0;
                letter-spacing: 5px;
                text-transform: uppercase;
                text-align: center;
            }

            &_descr{
                text-transform: uppercase;
                margin: 0;
                color: #E9E2D0;
                font-size: 24px;
                margin-top: 16px;
            }

            &_button{
                padding: 20px;
                font-size: 20px;
            }
            
        }
    }
}

@media only screen and (max-width: 767px) {
    .main{

        &__wrapper{

            &_title{
                margin: 0;
                font-size: 28px;
                color: #E9E2D0;
                letter-spacing: 5px;
                text-transform: uppercase;
                text-align: center;
            }

            &_descr{
                text-transform: uppercase;
                margin: 0;
                color: #E9E2D0;
                font-size: 20px;
                margin-top: 16px;
            }

            &_button{
                padding: 20px;
                font-size: 18px;
            }
            
        }
    }
}

@media only screen and (max-width: 500px) {
    .main{

        &__wrapper{

            &_title{
                margin: 0;
                font-size: 22px;
                color: #E9E2D0;
                letter-spacing: 5px;
                text-transform: uppercase;
                text-align: center;

                &_br{
                    display: none;
                }
            }

            &_descr{
                text-transform: uppercase;
                margin: 0;
                color: #E9E2D0;
                font-size: 18px;
                margin-top: 16px;
            }

            &_button{
                padding: 16px;
                font-size: 16px;
            }
            
        }
    }
}